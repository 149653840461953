import type { SocialApiAdapter } from '../social-api';
import achievementResponse from './data/achievements.json';
import featureFlagsResponse from './data/feature-flags.json';
import type { components } from '../types/social-api-types';

async function getUserAchievementBySlug() {
  return Promise.resolve(
    achievementResponse as components['schemas']['ProfileAchievementDetail']
  );
}

async function markAchievementAsShown() {
  return Promise.resolve();
}

async function getFeatureFlags() {
  return Promise.resolve(
    featureFlagsResponse as components['schemas']['FeatureFlagsResponse']
  );
}

function banUser() {
  return Promise.resolve(null);
}

function banUserPrivate() {
  return Promise.resolve({
    current_ban: null,
  } as components['schemas']['PrivateUserCurrentBan']);
}

function unbanUser() {
  return Promise.resolve(null);
}

function unbanUserPrivate() {
  return Promise.resolve({ current_ban: null });
}

function getAccessToken() {
  return Promise.resolve({
    access_token: 'token',
  });
}

const mockSocialAdapter: SocialApiAdapter = {
  getUserAchievementBySlug,
  markAchievementAsShown,
  getFeatureFlags,
  banUser,
  banUserPrivate,
  unbanUser,
  unbanUserPrivate,
  getAccessToken,
};

export default mockSocialAdapter;
