import { getCookie } from './get-cookie';
import { ACCESS_TOKEN_SESSION_KEY } from '../constants';

type RequestOptions = {
  method: 'GET' | 'POST' | 'DELETE' | 'PUT';
  headers: Record<string, string>;
  referrerPolicy?: ReferrerPolicy;
  credentials?: RequestCredentials;
  body?: any;
};

const { SOCIAL_API_URL, MERCURY_API_URL } = window.CONSTANTS;
export const SOCIAL_API = `${SOCIAL_API_URL}/api/v23`;
export const SOCIAL_API_PRIVATE = `${SOCIAL_API_URL}/private/v26`;
export const MERCURY_API = `${MERCURY_API_URL}/public/v1`;

const getApi = (api: string, includeToken = false) => ({
  get: get(api, includeToken),
  post: post(api, includeToken),
  put: put(api, includeToken),
  delete: _delete(api, includeToken),
});

function get(
  api: string,
  includeToken: boolean
): <T>(url: string, fullResponse?: boolean) => Promise<T> {
  return <T>(url: string, fullResponse?: boolean) =>
    fetch(api + url, getRequestOptions(includeToken, 'GET')).then((r) =>
      handleResponse<T>(r, fullResponse)
    );
}

function post(
  api: string,
  includeToken: boolean
): <T, U>(url: string, body: T) => Promise<U> {
  return <T, U>(url: string, body: T) =>
    fetch(api + url, getRequestOptions<T>(includeToken, 'POST', body)).then(
      (r) => handleResponse<U>(r)
    );
}

function put(
  api: string,
  includeToken: boolean
): <T, U>(url: string, body?: T) => Promise<U | undefined> {
  return <T, U>(url: string, body?: T) =>
    fetch(api + url, getRequestOptions<T>(includeToken, 'PUT', body)).then(
      (r) => handleResponse<U>(r)
    );
}

function _delete(
  api: string,
  includeToken: boolean
): (url: string) => Promise<Response> {
  return (url: string) =>
    fetch(api + url, getRequestOptions(includeToken, 'DELETE'));
}

// helper functions
export function handleResponse<T>(
  response: Response,
  fullResponse?: boolean
): Promise<T> {
  return response
    .json()
    .then((data) => {
      if (!response.ok || data.status !== 'ok') {
        const errorText = data?.error?.code || response.statusText;
        throw errorText;
      }
      return fullResponse ? data : data.data;
    })
    .catch((err) => {
      throw String(err);
    });
}

export function getRequestOptions<T>(
  includeToken: boolean,
  method: RequestOptions['method'],
  body?: T
) {
  const requestOptions: RequestOptions = {
    method,
    credentials: 'include',
    // По умолчанию, fetch не отправляет полный Referer при cross-origin
    // запросах. Но у нас он используется, например для аналитики. Поэтому
    // меняем политику на передачу полного Referer'a, кроме HTTP запросов,
    // которых у нас по идее быть не должно
    referrerPolicy: 'no-referrer-when-downgrade',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  // Куку x_csrf подставляет соц.платформа
  // чтобы приложение могло запрашивать/менять данные
  const csrf = getCookie('x_csrf');
  if (csrf) {
    requestOptions.headers['X-CSRFToken'] = csrf;
  }

  if (body) {
    requestOptions.body = JSON.stringify(body);
  }

  if (includeToken) {
    const token = sessionStorage.getItem(ACCESS_TOKEN_SESSION_KEY);
    if (token) {
      requestOptions.headers.Authorization = `Bearer ${token}`;
    }
  }

  return requestOptions;
}

export const socialApi = {
  ...getApi(SOCIAL_API),
};

export const socialApiPrivate = {
  ...getApi(SOCIAL_API_PRIVATE, true),
};

export const mercuryApi = {
  ...getApi(MERCURY_API),
};
